/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

body {
  background-color: black;
  width: 100%;
  height: 100%;
  font-family: "Jolly Lodger", cursive;
  font-size: 2rem;
  color: #999;
}

html,
body {
  -webkit-overflow-scrolling: touch;
}

a {
  color: darkslateblue;
}

/*
ng-bootstrap
*/

.modal-header {
  color: #ddd;
}

.dark-modal .modal-content {
  border: #999 4px solid;
  background-color: black;
  color: #757575;
  font-size: 1.7rem;
}
.dark-modal .close {
  color: #757575;
}

.dark-modal .modal-title {
  font-size: 1.8rem;
  font-weight: 600;
}

/* change styles for modal */
.delete {
  background-color: red;
  font-family: "Creepster";
  font-size: 20px;
}

.cancel {
  background-color: grey;
  font-family: "Creepster";
  font-size: 20px;
}

.submit {
  font-family: "Creepster";
  font-size: 20px;
  margin-left: 10px;
}

.btn-outline-primary {
  color: #ddd;
  border-color: #ccc;
}

.btn-outline-primary:active {
  background-color: #ccc;
  border-color: #ccc;
}

/* .dropdown-item {
  font-size: 1.7rem;
}

.dropdown-item :active {
  background-color: #999;
} */
